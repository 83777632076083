<template>


  <div class="w-1/3 px-4 sm:px-6 lg:px-8 self-center"
       data-aos="zoom-in"
       data-delay="2000"
       data-aos-offset="300"
  >
    <svg
        class="mx-auto mb-6 lg:mb-12"
        xmlns="http://www.w3.org/2000/svg"
        width="51.885" height="101"
        viewBox="0 0 51.885 101"

    >
      <g id="Icon" transform="translate(0 -0.021)">
        <path id="Shape"
              d="M14.561,53.364a1.055,1.055,0,0,1-.307-.745V50.679H11.347A1.049,1.049,0,0,1,10.3,49.626V38.853H9.58a1.051,1.051,0,0,1,0-2.1H10.3v-.584A19.155,19.155,0,0,1,22.069.243,19.183,19.183,0,0,1,27.8,36.168v.585h.721a1.051,1.051,0,0,1,0,2.1H27.8v1.962h.721a1.053,1.053,0,0,1,0,2.1H27.8v1.733h.721a1.051,1.051,0,0,1,0,2.1H27.8v2.868a1.056,1.056,0,0,1-.306.745,1.044,1.044,0,0,1-.739.309H23.85v1.939a1.049,1.049,0,0,1-1.044,1.053H15.3A1.044,1.044,0,0,1,14.561,53.364Z"
              transform="translate(7.006 7.193)"/>
        <rect id="Rectangle" width="5.413" height="0.888" transform="translate(23.351 57.872)"
              fill="rgba(69,80,230,0.16)"/>
        <path id="Shape-2" data-name="Shape"
              d="M10.3,34.649V33.412a.415.415,0,0,0-.005-.069.715.715,0,0,0-.013-.116.5.5,0,0,0-.023-.1c-.011-.03-.019-.065-.03-.1a.9.9,0,0,0-.044-.094c-.014-.028-.029-.057-.046-.084a.923.923,0,0,0-.058-.08c-.022-.029-.044-.058-.067-.085s-.048-.046-.074-.069a.8.8,0,0,0-.077-.063,1.1,1.1,0,0,0-.1-.058.432.432,0,0,0-.061-.037A17.066,17.066,0,0,1,16.961,0h0A17.066,17.066,0,0,1,24.22,32.465a.581.581,0,0,0-.06.037.946.946,0,0,0-.1.058.82.82,0,0,0-.084.065c-.025.023-.051.044-.074.069a1.065,1.065,0,0,0-.066.084.809.809,0,0,0-.056.079.427.427,0,0,0-.047.085.449.449,0,0,0-.046.093.839.839,0,0,0-.028.1.528.528,0,0,0-.025.1c-.008.039-.009.076-.014.116,0,.024-.005.046-.005.069v1.237Z"
              transform="translate(9.098 9.297)" fill="#5faaa8"/>
        <path id="Path"
              d="M1.047,4.928a1.041,1.041,0,0,0,.739-.308,1.053,1.053,0,0,0,.305-.744V1.022A1.046,1.046,0,0,0,0,1.022V3.877a1.055,1.055,0,0,0,.307.744,1.044,1.044,0,0,0,.74.308Z"
              transform="translate(25.012 0.021)"/>
        <path id="Path-2" data-name="Path"
              d="M1.56,4.049a1.042,1.042,0,0,0,1.428.384A1.054,1.054,0,0,0,3.37,3L1.952.526A1.043,1.043,0,0,0,.523.141,1.055,1.055,0,0,0,.14,1.577Z"
              transform="translate(12.607 3.333)"/>
        <path id="Path-3" data-name="Path"
              d="M.522,1.963,2.98,3.391a1.03,1.03,0,0,0,.522.141A1.052,1.052,0,0,0,4.025,1.57L1.568.141A1.042,1.042,0,0,0,.141.525,1.054,1.054,0,0,0,.522,1.96Z"
              transform="translate(3.527 12.462)"/>
        <path id="Path-4" data-name="Path"
              d="M1.075,2.1h2.84a1.044,1.044,0,0,0,.93-.517,1.056,1.056,0,0,0,0-1.068A1.044,1.044,0,0,0,3.915,0H1.075a1.044,1.044,0,0,0-.93.517,1.056,1.056,0,0,0,0,1.068A1.044,1.044,0,0,0,1.075,2.1Z"
              transform="translate(0.171 24.939)"/>
        <path id="Path-5" data-name="Path"
              d="M4.411.528A1.044,1.044,0,0,0,2.98.141L.524,1.569A1.054,1.054,0,0,0,.14,3a1.043,1.043,0,0,0,1.428.386L4.027,1.962A1.053,1.053,0,0,0,4.411.528Z"
              transform="translate(3.524 35.983)"/>
        <path id="Path-6" data-name="Path"
              d="M3.523,3.54a1.052,1.052,0,0,0,.525-1.962L1.589.153A1.041,1.041,0,0,0,.53.137,1.054,1.054,0,0,0,.544,1.974L3,3.4a1.035,1.035,0,0,0,.521.141Z"
              transform="translate(44.021 35.974)"/>
        <path id="Path-7" data-name="Path"
              d="M0,1.051A1.049,1.049,0,0,0,1.046,2.1H3.883a1.051,1.051,0,0,0,0-2.1H1.046a1.043,1.043,0,0,0-.74.308A1.054,1.054,0,0,0,0,1.051Z"
              transform="translate(46.986 24.939)"/>
        <path id="Path-8" data-name="Path"
              d="M1.045,3.542A1.029,1.029,0,0,0,1.567,3.4L4.025,1.972A1.054,1.054,0,0,0,4.037.137,1.041,1.041,0,0,0,2.979.152L.522,1.579a1.054,1.054,0,0,0,.523,1.964Z"
              transform="translate(44.044 12.454)"/>
        <path id="Path-9" data-name="Path"
              d="M.525,4.435a1.056,1.056,0,0,0,.522.14,1.041,1.041,0,0,0,.905-.527L3.371,1.578a1.057,1.057,0,0,0,0-1.052,1.043,1.043,0,0,0-1.812,0L.14,3A1.058,1.058,0,0,0,.525,4.435Z"
              transform="translate(35.999 3.335)"/>
        <path id="Shape-3" data-name="Shape"
              d="M42.547,39.5H9.167a1.044,1.044,0,0,1-.994-.724L2.515,21.331A3.468,3.468,0,0,1,0,18v-.423A3.461,3.461,0,0,1,3.452,14.1H25.012V1.022a1.047,1.047,0,0,1,2.092,0V14.1H48.265a3.461,3.461,0,0,1,3.451,3.464v.424A3.473,3.473,0,0,1,49.2,21.325L43.542,38.774a1.045,1.045,0,0,1-.991.724Z"
              transform="translate(0 61.523)"/>
        <path id="Path-10" data-name="Path"
              d="M47.532,1.366v.423a1.361,1.361,0,0,1-1.356,1.364H1.361A1.363,1.363,0,0,1,0,1.789V1.366A1.365,1.365,0,0,1,1.361,0H46.173A1.363,1.363,0,0,1,47.532,1.366Z"
              transform="translate(2.091 77.73)" fill="rgba(69,80,230,0.18)"/>
        <path id="Shape-4" data-name="Shape"
              d="M5.566,11.551C.828,8.514.035,2.341,0,2.081A.751.751,0,0,1,0,2c0-.033,0-.061,0-.1S.01,1.832.015,1.8.024,1.734.03,1.7A1.028,1.028,0,0,1,.066,1.6.693.693,0,0,1,.1,1.515c.015-.032.032-.062.05-.093S.183,1.366.2,1.338a.588.588,0,0,1,.063-.074.858.858,0,0,1,.072-.079.9.9,0,0,1,.076-.062c.029-.02.056-.041.085-.06a1.021,1.021,0,0,1,.1-.051C.616,1,.635.99.655.981c.244-.1,6.008-2.368,10.936.35,3.1,1.708,5.114,4.959,6,9.664a.191.191,0,0,1,0,.038.611.611,0,0,1,.009.067v.142a.329.329,0,0,1-.005.061.462.462,0,0,1-.009.069c-.005.025-.009.05-.015.075l-.017.057c-.006.022-.014.042-.022.063a.642.642,0,0,1-.03.067.341.341,0,0,1-.03.061.162.162,0,0,1-.014.03.186.186,0,0,1-.023.036L17.4,11.8a.093.093,0,0,1-.014.02.116.116,0,0,0-.009.017c-.025.029-.052.057-.077.085-.006,0-.009.01-.014.014l-.032.029a.421.421,0,0,0-.034.028q-.036.03-.072.057l-.038.024c-.01,0-.023.015-.028.017s-.008.01-.014.01a.864.864,0,0,1-.1.05l-.018.01-.048.015-.019.01a17.864,17.864,0,0,1-5.517.975l-.2,0A10.525,10.525,0,0,1,5.566,11.551Z"
              transform="translate(6.418 60.147)"/>
        <path id="Shape-5" data-name="Shape"
              d="M6.231,13.164a17.788,17.788,0,0,1-5.516-.975l-.022-.01-.046-.015-.019-.009c-.036-.015-.071-.032-.105-.05a.027.027,0,0,0-.014-.01.182.182,0,0,1-.025-.018l-.038-.023C.421,12.036.4,12.016.374,12a.362.362,0,0,1-.033-.029.349.349,0,0,1-.033-.028l-.014-.015q-.04-.04-.076-.084a.048.048,0,0,1-.011-.017.166.166,0,0,1-.013-.019c-.01-.014-.02-.028-.029-.042a.3.3,0,0,1-.024-.037A.207.207,0,0,1,.127,11.7a.528.528,0,0,1-.03-.06.529.529,0,0,1-.029-.069c-.008-.022-.017-.042-.023-.062s-.01-.037-.015-.06-.011-.053-.015-.074A.346.346,0,0,1,0,11.306v-.062a.712.712,0,0,1,0-.074A.6.6,0,0,1,0,11.1a.573.573,0,0,1,.009-.067l0-.038c.881-4.705,2.9-7.957,6-9.665C10.936-1.388,16.7.883,16.944.982a.394.394,0,0,1,.072.038l.085.042a.82.82,0,0,1,.1.069c.023.018.044.034.066.055a.82.82,0,0,1,.079.084.538.538,0,0,1,.056.065,1.022,1.022,0,0,1,.06.093c.014.029.032.057.044.085a.816.816,0,0,1,.037.1.6.6,0,0,1,.023.094c.008.033.015.074.019.1a.8.8,0,0,1,.011.1.858.858,0,0,1,0,.114v.066c-.03.263-.827,6.435-5.563,9.472a10.544,10.544,0,0,1-5.6,1.609Z"
              transform="translate(27.698 60.143)"/>
      </g>
    </svg>
  </div>

  <div class="w-10/12 lg:w-2/3 p-4 lg:p-10 bg-gray-50 text-center lg:text-left"
  >

    <h3 class="text-xl mb-5 font-Manrope">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. </h3>

    <p class="font-Helvetica max-w-4xl">Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,
      ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo,
      fringilla vel, aliquet nec, vulputate eget, arcu.Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis,
      ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo,
      fringilla vel, aliquet nec, vulputate eget, arcu. </p>

  </div>


</template>


<script>

export default {
    name: "ServicesSection",

/*    mounted() {
        AOS.init({
            once: true,
            duration: 1000,
            easing: 'ease-out-sine',
        })
    },*/
}
</script>

<style scoped>

</style>
